module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.whiteclaw.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wc-us","accessToken":"MC5ZM1oxOXhBQUFDZ0F1UHZW.dCoHU--_ve-_ve-_ve-_vRDvv70Je--_vQTvv73vv73vv70zV--_vToZ77-9aO-_ve-_vX5kBHnvv73vv70","promptForAccessToken":true,"apiEndpoint":"https://wc-us.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","display":"minimal-ui","icon":"/codebuild/output/src3459215888/src/codestar-connections.us-west-2.amazonaws.com/git-http/603400662265/us-west-2/42625e79-deed-4f1d-8499-8b7906bc0d98/MarkAnthonyGroup/www.whiteclaw.com/src/assets/images/favicon-icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7d6850888c5f7edd14560b01f2140d09"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-556QGLZ","includeInDevelopment":true,"enableWebVitalsTracking":true,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            pageType: window.pageType,\n          }\n        }"},"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"HSLB6As1kQkXDbTAmIKg7L1bbekNhFTb","devKey":"qBn58ZgspH0wo5Ya3Lc0IZRT0tg4X8q4","trackPage":false,"trackPageImmediately":false,"trackPageOnlyIfReady":true,"trackPageOnRouteUpdate":true,"trackPageOnRouteUpdateDelay":50,"trackPageWithTitle":false,"delayLoad":false,"delayLoadDelay":1000,"delayLoadUntilActivity":false,"manualLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
